import React, { Component } from "react"
import styled, { css } from 'styled-components'
import { find } from 'lodash'

import { container, heading, bgImage, button, hoverState } from "../styles/global"
import { red, blue, pink, yellow } from "../styles/colors";
import { isClient, media, width } from "../styles/utils";

import landingLogo from '../assets/images/landing-logo.svg'

class Landing extends Component {

	renderSocial = (params) => {
		const socials = [
			{
				name: 'Facebook',
				url: 'https://www.facebook.com/musicinexilebybedroomsuck/'
			},
			{
				name: 'Instagram',
				url: 'https://www.instagram.com/music.in.exile'
			}
		]

		const links = socials.map((social, i) => {
			return (
				<Link
					href={social.url}
				>
					{social.name}
				</Link>
			)
		})

		return (
			<Links>
				{links}
			</Links>
		)
	  
	}

  	render() {
		return (
			<Wrapper>

                <Logo
                    src={landingLogo}
                />

				{this.renderSocial()}

			</Wrapper>
		)	
  	}
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;	
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	overflow: hidden;
	background: ${yellow};
	padding: 0 32px;
	box-sizing: border-box;

	${media.tablet`
		flex-direction: column;
		max-height: none;
	`}
`

const Logo = styled.img`
	width: 100%;
	height: auto;
	max-width: 500px;
`

const Links = styled.div`
	display: flex;
	margin-top: 50px;

	${media.phone`
		margin-top: 32px;
	`}
`

const Link = styled.a`
	font-family: 'Cooper';
	font-size: 30px;
	${hoverState}
	cursor: pointer;

	${media.phone`
		font-size: 24px;
	`}

	&:not(:last-child) {
		margin-right: 32px;
	}
`

export default Landing
